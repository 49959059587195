import { NavLink } from 'react-router-dom';
import { contacts } from '../../static/contacts';
import './cta.scss';

const CallToAction = ({ title }) => (
  <section className="cta">
    <div className="container">
      <h2 className="section-title">Need Your {title?.split(' ')[0]} Fixed?</h2>

      <div className="cta__links">
        <NavLink to="/book-now" className="red-button" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
          Book a Service
        </NavLink>
        <span>
          or call us <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </span>
      </div>
    </div>
  </section>
);

export default CallToAction;
