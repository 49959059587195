import Slider from 'react-slick';
import './reviews.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ data }) => {
  return (
    <section className="reviews">
      <div className="container">
        <h2 className="section-title">
          What Our <span>Clients Have to Say</span>
        </h2>

        <Slider className="reviews__list" {...sliderSettings}>
          {data?.map((item, index) => (
            <ReviewCard {...{ review: item, key: index }} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.resource) {
    case 'Facebook':
      icon = 'icon-facebook';
      break;
    case 'Yelp':
      icon = 'icon-yelp';
      break;
    default:
      icon = 'icon-google';
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <div className="reviews__list--card__header--resource">
          <i className={icon} />
        </div>
        <div className="reviews__list--card__header--stars">
          <i className="icon-star" />
          <i className="icon-star" />
          <i className="icon-star" />
          <i className="icon-star" />
          <i className="icon-star" />
        </div>
      </div>
      <div className="reviews__list--card__body">
        <span className="reviews__list--card__date">{review.date}</span>
        <span className="reviews__list--card__name">{review.customer_name}</span>
        <p>{review.text}</p>
      </div>
    </div>
  );
};
