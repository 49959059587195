import './areas.scss';

// const served_areas = [
//   'Algona',
//   'Auburn',
//   'Beaux Arts Village',
//   'Bellevue',
//   'Black Diamond',
//   'Bothell',
//   'Burien',
//   'Carnation',
//   'Clyde Hill',
//   'Covington',
//   'Des Moines',
//   'Duvall',
//   'Enumclaw',
//   'Federal Way',
//   'Hunts Point',
//   'Issaquah',
//   'Kenmore',
//   'Kent',
//   'Kirkland',
//   'Lake Forest Park',
//   'Maple Valley',
//   'Medina',
//   'Mercer Island',
//   'Milton',
//   'Newcastle',
//   'Normandy Park',
//   'North Bend',
//   'Pacific',
//   'Redmond',
//   'Renton',
//   'Sammamish',
//   'SeaTac',
//   'Seattle',
//   'Shoreline',
//   'Skykomish',
//   'Snoqualmie',
//   'Tukwila',
//   'Woodinville',
//   'Yarrow Point',
// ];

const served_areas = [
  'Manhattan, NY',
  'Brooklyn, NY',
  'Queens, NY',
  'Staten Island, NY',
  'Bayonne, NJ',
  'Jersey City, NJ',
  'Hoboken, NJ',
  'Union City, NJ',
  'Passaic, NJ',
  'Garfield, NJ',
  'East Orange, NJ',
  'Newark, NJ',
  'Newark, NJ',
  'New Brunswick, NJ',
  'Perth Amboy, NJ',
  'Long Branch, NJ',
  'Trenton, NJ',
];

const Areas = () => (
  <section className="areas">
    <div className="container">
      <h2 className="section-title">
        Areas <span>We Serve</span>
      </h2>

      <ul>
        {served_areas.map((el, index) => (
          <li key={index}>{el}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Areas;
