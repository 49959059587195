import { useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner, ContentBlock } from '../components';

import { ADMIN_URL } from '../App';

const PrivacyPolicy = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/legal?slug=privacy-policy`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout>
      <Seo data={data[0]?.yoast_head_json} />
      <section>
        <ContentBlock title={data[0]?.title.rendered} content={data[0]?.content.rendered} />
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
