import { NavLink } from 'react-router-dom';
import { contacts } from '../../static/contacts';

import './hero.scss';

const awards = [
  {
    url: 'https://control.a-techrepair.com/wp-content/uploads/2022/08/1.svg',
    title: 'home advisor',
  },
  {
    url: 'https://control.a-techrepair.com/wp-content/uploads/2022/08/2.svg',
    title: 'home advisor',
  },
  {
    url: 'https://control.a-techrepair.com/wp-content/uploads/2022/08/3.svg',
    title: 'home advisor',
  },
  {
    url: 'https://control.a-techrepair.com/wp-content/uploads/2022/08/4.svg',
    title: 'home advisor',
  },
  {
    url: 'https://control.a-techrepair.com/wp-content/uploads/2022/08/5.svg',
    title: 'home advisor',
  },
];

const rating = [
  {
    title: 'Google',
    logo: '/assets/images/google-logo-color.svg',
  },
  {
    title: 'Yelp',
    logo: '/assets/images/yelp-logo-color.svg',
  },
];

const Hero = ({ title }) => {
  return (
    <section className="hero">
      <div className="hero__content">
        <div className="hero__content--mobile_img">
          {/* <img
            src="https://admin.bcappliancerepair.com/wp-content/uploads/2023/01/hero-right-bg.jpg"
            alt="mobile only img"
          /> */}
          <img
            src="https://admin.bcappliancerepair.com/wp-content/uploads/2023/12/toporinstudio_2_Appliance_Repair_Technicians_in_uniform_smiling_423c231f-3d9e-49ab-a9a3-135dce38776d.png"
            alt="mobile only"
          />
        </div>

        <h1>
          Rated #1
          <br />
          Local {title} Service
          {/* <br className="mobile-br" /> */}
          {/* in New York, New Jersey, and Surrounding Areas */}
        </h1>

        {/* <ul>
          <li>Fully Insured</li>
          <li>Certified Technicians</li>
          <li>Transparent Pricing</li>
        </ul> */}

        <NavLink to="/book-now" className="red-button" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
          Book a Service
        </NavLink>

        <span>
          or call us <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </span>
      </div>

      <div className="hero__banners">
        <div className="hero__banners--awards">
          {awards.map((el, index) => (
            <div className="hero__banners--awards__item" key={index}>
              <img src={el.url} alt={el.title} />
            </div>
          ))}
        </div>
        <div className="hero__banners--rating">
          {rating.map((el, index) => (
            <RatingBanner key={index} banner={el} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;

const RatingBanner = ({ banner }) => (
  <div className="hero__banners--rating__card">
    <div className="hero__banners--rating__card--logo">
      <img src={banner.logo} alt={banner.title} />
    </div>

    <div className="hero__banners--rating__card--title">
      <span>{banner.title} Rating</span>
      <div className="hero__banners--rating__card--stars">
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
      </div>
    </div>
  </div>
);
