import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import PageTemplate from './pages/PageTemplate';
import Booking from './pages/Booking';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessibilityStatement from './pages/AccessibilityStatement';
import TermsOfUse from './pages/TermsOfUse';
import NotFound from './pages/NotFound';

import { Spinner } from './components';

export const ADMIN_URL = 'https://admin.bcappliancerepair.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages?per_page=100`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/appliance-repair" />} />

        {data?.map((page) => (
          <Route path={`/${page.slug}`} element={<PageTemplate />} key={page.id} />
        ))}
        <Route path="/book-now" element={<Booking />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        <Route path="/404" element={<NotFound />} />

        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
