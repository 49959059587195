import './pageContent.scss';

const PageContent = ({ title, content, image }) => (
  <section className="page-content">
    <div className="page-content__text">
      <h2 className="section-title">
        <span>{title}</span> Experts
      </h2>

      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
    <div className="page-content__image">
      <img src={image} alt={title} />
    </div>
  </section>
);

export default PageContent;
