import './getInTouch.scss';

import { GetInTouchForm } from '../../components';

const GetInTouch = () => (
  <section className="get-in-touch">
    <div className="container">
      <h2 className="section-title">
        We're Available,
        <br />
        Contact Us Today
      </h2>

      <GetInTouchForm />
    </div>
  </section>
);

export default GetInTouch;
