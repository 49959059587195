import './satisfaction.scss';

const cards = [
  {
    logo: '/assets/images/google-color-big.svg',
    reviews: 154,
  },
  {
    logo: '/assets/images/fb-color-big.svg',
    reviews: 256,
  },
  {
    logo: '/assets/images/yelp-color-big.svg',
    reviews: 202,
  },
];

const Satisfaction = () => (
  <section className="satisfaction">
    <div className="container">
      <h2 className="section-title">
        <span>100%</span> Satisfaction Guaranteed
      </h2>

      <div className="satisfaction__links">
        {cards.map((el, index) => (
          <SatisfactionCard key={index} logo={el.logo} reviews={el.reviews} />
        ))}
      </div>
    </div>
  </section>
);

export default Satisfaction;

const SatisfactionCard = ({ logo, reviews }) => (
  <div>
    <div className="satisfaction__links--logo">
      <img src={logo} alt="google" />
    </div>

    <div className="satisfaction__links--reviews">
      <div>
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
      </div>
      <span>{reviews} reviews</span>
    </div>
  </div>
);
