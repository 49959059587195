import { contacts } from '../../static/contacts';
import './callUs.scss';

const CallUs = ({ title }) => (
  <section className="call-us">
    <div className="container">
      <h2 className="section-title">
        {title?.split(' ')[0]} broken?
        <br /> Let us <span>help you!</span>
      </h2>

      <p>
        Don't delay getting your daily routine back on track. BC Appliance Repair will fix your malfunctioning{' '}
        {title?.split(' ')[0].toLowerCase()} with our same-day & next-day repair service.
      </p>

      <div className="call-us__link">
        <a href={`tel:${contacts.phone}`} className="red-button">
          {contacts.phone}
        </a>

        <span>Call us now!</span>
      </div>

      <div className="call-us__payments">
        <img src="/assets/images/payments/mastercard.svg" alt="mastercard" />
        <img src="/assets/images/payments/visa.svg" alt="visa" />
        <img src="/assets/images/payments/discover.svg" alt="discover" />
        <img src="/assets/images/payments/ae.svg" alt="ae" />
      </div>
    </div>
  </section>
);

export default CallUs;
