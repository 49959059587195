import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner } from '../components';
import {
  Hero,
  WhyWe,
  GetInTouch,
  Satisfaction,
  CallToAction,
  PageContent,
  Services,
  Reviews,
  Areas,
  MakesAndModels,
  CallUs,
} from '../sections';

const PageTemplate = () => {
  const [pageData, setPageData] = useState();
  const slug = window.location.href.split('/')[3];

  const { isLoading, data, error } = useFetch(`https://admin.bcappliancerepair.com/wp-json/wp/v2/pages?slug=${slug}`);

  useEffect(() => {
    if (data) {
      setPageData(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId={pageData?.slug}>
      <Seo data={pageData?.yoast_head_json} />
      <Hero title={pageData?.title.rendered} />
      <WhyWe title={pageData?.title.rendered} />
      <GetInTouch />
      <Satisfaction />
      <CallToAction title={pageData?.title.rendered} />
      <PageContent
        title={pageData?.title.rendered}
        content={pageData?.content.rendered}
        image={pageData?.acf.main_image}
      />
      <Services />
      <Reviews data={pageData?.acf.reviews} />
      <Areas />
      <MakesAndModels />
      <CallUs title={pageData?.title.rendered} />
    </Layout>
  );
};

export default PageTemplate;
