import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import './bookingForm.scss';

const BookingForm = ({ prevLocation }) => {
  const [defaultServices, setDefaultService] = React.useState('');
  const [bookingFrom, setBookingForm] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type_of_service: defaultServices || '',
    message: '',
  });

  React.useEffect(() => {
    switch (prevLocation) {
      case 'refrigerator-repair':
        setDefaultService('Refrigerator Repair');
        break;
      case 'freezer-repair':
        setDefaultService('Freezer Repair');
        break;
      case 'washer-repair':
        setDefaultService('Washer Repair');
        break;
      case 'dryer-repair':
        setDefaultService('Dryer Repair');
        break;
      case 'dishwasher-repair':
        setDefaultService('Dishwasher Repair');
        break;
      case 'range-repair':
        setDefaultService('Range Repair');
        break;
      case 'oven-repair':
        setDefaultService('Oven Repair');
        break;
      case 'microwave-repair':
        setDefaultService('Microwave Repair');
        break;
      default:
        setDefaultService('');
        break;
    }
  }, [prevLocation]);

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setBookingForm((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.entries(bookingFrom).map((el) => formData.append(el[0], el[1]));

    try {
      const response = await axios.post(
        'https://admin.bcappliancerepair.com/wp-json/contact-form-7/v1/contact-forms/27/feedback',
        formData,
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        e.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <div className="booking-form-wrapper">
      <h2 className="section-title">Schedule a Service</h2>
      <form onSubmit={sendEmail}>
        <select name="type_of_service" required value={defaultServices} onChange={handleChange}>
          <option hidden>Type of service *</option>
          <option value="Refrigerator Repair">Refrigerator Repair Diagnosis</option>
          <option value="Freezer Repair">Freezer Repair Diagnosis</option>
          <option value="Washer Repair">Washer Repair Diagnosis</option>
          <option value="Dryer Repair">Dryer Repair Diagnosis</option>
          <option value="Dishwasher Repair">Dishwasher Repair Diagnosis</option>
          <option value="Range Repair">Range Repair Diagnosis</option>
          <option value="Oven Repair">Oven Repair Diagnosis</option>
          {/* <option value="Microwave Repair">Microwave Repair Diagnosis</option> */}
        </select>
        <input name="first_name" required type="text" placeholder="First Name *" onChange={handleChange} />
        <input name="last_name" required type="text" placeholder="Last Name *" onChange={handleChange} />
        <input name="email" required type="email" placeholder="Email *" onChange={handleChange} />
        <input name="phone" required type="phone" placeholder="Phone *" onChange={handleChange} />

        <textarea
          name="message"
          required
          placeholder="Please let us know if you have any special requests *"
          onChange={handleChange}
        />

        <p className="flex">
          <Lock /> Data protected and privacy
        </p>

        <input type="submit" value="Submit" className="red-button" />
      </form>
    </div>
  );
};

export default BookingForm;

const Lock = () => (
  <svg
    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="LockIcon"
  >
    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
  </svg>
);
