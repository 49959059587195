import { contacts } from '../../static/contacts';

import './whyWe.scss';

const grid = [
  {
    title: 'Expert Technicians',
    icon: '/assets/images/experts.svg',
    description:
      'Our factory-trained technicians use advanced tools and industry-standard parts to give your appliance a lasting repair.',
  },
  {
    title: 'Competitive Pricing',
    icon: '/assets/images/price.svg',
    description:
      'We are committed to providing transparent and honest pricing. We never overcharge and there are no hidden fees.',
  },
  {
    title: 'Fully Insured',
    icon: '/assets/images/labor.svg',
    description: 'Our team is fully insured, licensed, and highly trained to perform comprehensive repairs.',
  },
  {
    title: 'Same-Day & Next-Day Service',
    icon: '/assets/images/repair.svg',
    description:
      'We understand the value of your time and the need to resolve issues promptly. We assure and offer same-day & next-day repairs.',
  },
];

const WhyWe = ({ title }) => (
  <section className="why-we">
    <div className="container">
      <div className="why-we__content">
        <h2 className="section-title">
          Why <span>BC</span> {title}?
        </h2>

        <div className="why-we__content--grid">
          {grid.map((el, index) => (
            <GridItem key={index} data={el} />
          ))}
        </div>
      </div>

      <div className="why-we__coupon">
        <div className="why-we__coupon--inner">
          <h2>
            <span>FREE</span> Service Call
          </h2>
          <span>With Repair</span>
          <p>
            Always Low Cost &<br />
            High Quality Service
          </p>
          <a href="/book-now" className="red-button">
            Book Now
          </a>
          <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </div>
      </div>
    </div>
  </section>
);

export default WhyWe;

const GridItem = ({ data }) => (
  <div className="why-we__content--grid__item">
    <div className="why-we__content--grid__item--header">
      <div className="why-we__content--grid__item--header__icon">
        <img src={data.icon} alt={data.title} />
      </div>
      <h4>{data.title}</h4>
    </div>
    <p>{data.description}</p>
  </div>
);
